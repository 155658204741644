import { Callback, PageNavAction } from "@/types/types";
import { MoneyModule } from "@/vuex/modules/money/money.index";
import { Component, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";

@Component({
  render: c => c("router-view")
})
export default class FeatureComponent extends Vue {
  @Action("changeTitle", { namespace: "MegaMenuModule" })
  public changeTitleAction!: Callback;
  @Action("changeIcon", { namespace: "MegaMenuModule" })
  public changeIcon!: Callback;
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;
  constructor() {
    super();
    this.setPageNav(null);
    this.changeTitleAction("money");
    this.changeIcon(require("@/assets/images/icon_primary_menu_money@2x.png"));
  }

  protected created() {
    this.$store.registerModule("MoneyModule", MoneyModule);
  }

  protected beforeDestroy() {
    this.$store.commit("MoneyModule/clearState");
    this.$store.unregisterModule("MoneyModule");
  }
}
