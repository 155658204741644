import { messagesService } from "@/services/messages.service";
import { moneyService } from "@/services/money.service";
import { RootState } from "@/vuex/types";
import { TablePagination } from "helix-vue-components";
import { ActionContext, ActionTree } from "vuex";
import { MoneyState } from "./money.types";

type MoneyActionContext = ActionContext<MoneyState, RootState>;
type MoneyActionTree = ActionTree<MoneyState, RootState>;

export const actions: MoneyActionTree = {
  async loadSafe(context: MoneyActionContext) {
    try {
      context.commit("setSafeLoading", true);
      const payload = await moneyService.getSafe();
      const pagination: TablePagination = await moneyService.getPagination(
        true
      );
      context.commit("setSafePagination", pagination);
      context.commit("setSafe", payload);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      context.commit("setSafeLoading", false);
    }
  },
  async loadOpenTills(context: MoneyActionContext) {
    try {
      context.commit("setOpenTillsLoading", true);
      const payload = await moneyService.getTills(true);
      const pagination: TablePagination = await moneyService.getPagination(
        true
      );
      context.commit("setOpenTillsPagination", pagination);
      context.commit("setOpenTills", payload);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      context.commit("setOpenTillsLoading", false);
    }
  },
  async loadClosedTills(context: MoneyActionContext) {
    try {
      context.commit("setClosedTillsLoading", true);
      const payload = await moneyService.getTills(false);
      const pagination: TablePagination = await moneyService.getPagination(
        true
      );
      context.commit("setClosedTillsPagination", pagination);
      context.commit("setClosedTills", payload);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      context.commit("setClosedTillsLoading", false);
    }
  },
  async loadPayouts(context: MoneyActionContext) {
    try {
      context.commit("setPayoutsLoading", true);
      const payload = await moneyService.getPayouts();
      const pagination = await moneyService.getPagination(true);
      context.commit("setPayoutsPagination", pagination);
      context.commit("setPayouts", payload);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      context.commit("setPayoutsLoading", false);
    }
  },
  async loadOpenTillsSummary(context: MoneyActionContext) {
    context.commit("setPayoutsLoading", true);
    try {
      const summary = await moneyService.getMoneySummary();
      context.commit("setOpenTillsSummary", summary);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      context.commit("setPayoutsLoading", false);
    }
  }
};
