import { MoneyState } from "@/vuex/modules/money/money.types";

export const state: MoneyState = {
  safeData: { events: [], cash: 0, boxId: 0 },
  safeLoading: false,
  safePagination: null,
  openTillsData: [],
  openTillsLoading: false,
  openTillsPagination: null,
  closedTillsData: [],
  closedTillsLoading: false,
  closedTillsPagination: null,
  payoutsData: [],
  payoutsLoading: false,
  payoutsPagination: null,
  openTillsSummary: null
};
