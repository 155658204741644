import { RootState } from "@/vuex/types";
import { Module } from "vuex";
import { actions } from "./money.actions";
import { getters } from "./money.getters";
import { mutations } from "./money.mutations";
import { state } from "./money.state";
import { MoneyState } from "./money.types";

const namespaced: boolean = true;
export const MoneyModule: Module<MoneyState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
