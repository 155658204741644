import { MoneyState, OpenTillsSummary } from "@/vuex/modules/money/money.types";
import { RootState } from "@/vuex/types";
import { TablePagination } from "helix-vue-components";
import { GetterTree } from "vuex";

type EmployeeGetter = GetterTree<MoneyState, RootState>;

export const getters: EmployeeGetter = {
  safeData(state: MoneyState) {
    return state.safeData;
  },
  safePagination(state: MoneyState): TablePagination | null {
    return state.safePagination;
  },
  safeLoading(state: MoneyState): boolean {
    return state.safeLoading;
  },
  openTillsData(state: MoneyState) {
    return state.openTillsData;
  },
  openTillsPagination(state: MoneyState): TablePagination | null {
    return state.openTillsPagination;
  },
  openTillsLoading(state: MoneyState): boolean {
    return state.openTillsLoading;
  },
  closedTillsData(state: MoneyState) {
    return state.closedTillsData;
  },
  closedTillsPagination(state: MoneyState): TablePagination | null {
    return state.closedTillsPagination;
  },
  closedTillsLoading(state: MoneyState): boolean {
    return state.closedTillsLoading;
  },
  payoutsData(state: MoneyState) {
    return state.payoutsData;
  },
  payoutsPagination(state: MoneyState): TablePagination | null {
    return state.payoutsPagination;
  },
  payoutsLoading(state: MoneyState): boolean {
    return state.payoutsLoading;
  },
  openTillsSummary(state: MoneyState): OpenTillsSummary | null {
    return state.openTillsSummary;
  }
};
