import { SafeData, Till } from "@/interfaces/money";
import { TablePagination } from "helix-vue-components";
import { MutationTree } from "vuex";
import { MoneyState, OpenTillsSummary } from "./money.types";

type VendorMutationTree = MutationTree<MoneyState>;

export const mutations: VendorMutationTree = {
  setSafe(state: MoneyState, data: SafeData) {
    state.safeData = data;
  },
  setSafeLoading(state: MoneyState, loading: boolean) {
    state.safeLoading = loading;
  },
  setSafePagination(state: MoneyState, pagination: TablePagination | null) {
    state.safePagination = {
      ...pagination!,
      dispatchAction: "MoneyModule/loadSafe"
    };
  },
  setOpenTills(state: MoneyState, data: Till[]) {
    state.openTillsData = data;
  },
  setOpenTillsLoading(state: MoneyState, loading: boolean) {
    state.openTillsLoading = loading;
  },
  setOpenTillsPagination(
    state: MoneyState,
    pagination: TablePagination | null
  ) {
    state.openTillsPagination = {
      ...pagination!,
      dispatchAction: "MoneyModule/loadOpenTills"
    };
  },
  setClosedTills(state: MoneyState, data: any[]) {
    state.closedTillsData = data;
  },
  setClosedTillsLoading(state: MoneyState, loading: boolean) {
    state.closedTillsLoading = loading;
  },
  setClosedTillsPagination(
    state: MoneyState,
    pagination: TablePagination | null
  ) {
    state.closedTillsPagination = {
      ...pagination!,
      dispatchAction: "MoneyModule/loadClosedTills"
    };
  },
  setPayouts(state: MoneyState, data: any[]) {
    state.payoutsData = data;
  },
  setPayoutsLoading(state: MoneyState, loading: boolean) {
    state.payoutsLoading = loading;
  },
  setPayoutsPagination(state: MoneyState, pagination: TablePagination | null) {
    state.payoutsPagination = {
      ...pagination!,
      dispatchAction: "MoneyModule/loadPayouts"
    };
  },
  setOpenTillsSummary(state: MoneyState, summary: OpenTillsSummary | null) {
    state.openTillsSummary = summary;
  },
  clearState(state: MoneyState) {
    state.openTillsSummary = null;
    state.openTillsData = state.closedTillsData = state.payoutsData = [];
    state.safeData = { events: [], cash: 0, boxId: 0 };
  }
};
